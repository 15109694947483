//STAFF PAGE
import {gql} from "@apollo/client";


export const GET_STAFF_QUERY = gql`
    query GetStaffByLocation ($dateAndLocation: dateAndLocation!) {
        staffByLocation (dateAndLocation: $dateAndLocation) {
            contract {
                contractTypeId
                contractModificationId
                contractModificationName
                foreigner
                lateWorker
                hourlyRate
                holidayHourlyRate
            }
            identityId
            email
            firstName
            lastName
            kanjiFirstName
            kanjiLastName
            role
            employeeId
            stationId
            teamLeader
            baseSalary
            globalPremium
            managerialPremium
            ignoreHourContribution
            restaurant {
                id
                name
                locationNumber
            }
            isRosterable
        }
    }
`;

// STAFF SKILLS
export const GET_EMPLOYEE_SKILLS_QUERY = gql`
    query GetEmployeeSkills($employeeId: Guid!) {
        employeeSkills(employeeId: $employeeId) {
            employeeSkillId
            name
            rating
            skillId
            sortOrder
            stationName
        }
    }
`;

export const CREATE_EMPLOYEE_SKILL_MUTATION = gql`
    mutation createEmployeeSkill($skill: employeeSkill!) {
        createEmployeeSkill(skill: $skill) {
            name
        }
    }
`;

export const UPDATE_EMPLOYEE_SKILL_MUTATION = gql`
    mutation updateEmployeeSkill($skill: employeeSkill!) {
        updateEmployeeSkill(skill: $skill) {
            name
        }
    }
`;

export const DELETE_EMPLOYEE_SKILL_MUTATION = gql`
    mutation deleteEmployeeSkill($employeeSkillId: Int!) {
        deleteEmployeeSkill(employeeSkillId: $employeeSkillId) {
            name
        }
    }
`;

// AVAILABILITIES
export const GET_AVAILABILITIES_QUERY = gql`
    query getAvailabilities($employeeId: Guid!, $start: Date!) {
        availabilities(employeeId: $employeeId, start: $start) {
            id,
            employeeId
            day
            start
            end
        }
    }
`;

export const CREATE_AVAILABILITY_MUTATION = gql`
    mutation CreateAvailability($availability: createAvailability!) {
        createAvailability(availability: $availability) {
            id
            employeeId
            day
            start
            end
        }
    }
`;

export const UPDATE_AVAILABILITY_MUTATION = gql`
    mutation UpdateAvailability($availability: updateAvailability!) {
        updateAvailability(availability: $availability) {
            id
            day
            start
            end
        }
    }
`;

export const DELETE_AVAILABILITY_MUTATION = gql`
    mutation DeleteAvailability($id: Guid!) {
        deleteAvailability(id: $id) {
            id
            employeeId
            day
        }
    }
`;

export const GET_AMENDABLE_WORKING_PATTERNS = gql`
    query GetAmendableEmployeeWorkingPatterns {
        amendableEmployeeWorkingPatterns {
            id
            start
            end
            amendmentStart
            amendmentEnd
            patterns {
                startTime
                endTime
                day
                dayNumber
                state
                available
            }
            workRules {
                maxDaysPerWeek
            }
        }
    }
`;

export const GET_MY_WORKING_PATTERN_IN_PERIOD = gql`
    query GetMyWorkingPatternInThePeriod($periodId: Int!) {
        myWorkingPatternInThePeriod(periodId: $periodId) {
            workingPattern {
                startTime
                endTime
                day
                dayNumber
                state
                available
            }
        }
    }

`;

export const UPDATE_STAFF_AVAILABILITY_PATTERN = gql`
    mutation UpdateStaffAvailabilityPatterns($workingPattern: updateWorkingPattern!) {
        updateWorkingPattern(workingPattern: $workingPattern) {
            workingPattern {
              dayNumber
              available
              startTime
              endTime
              state
            }
        }
    }
`;

export const UPDATE_STAFF_AVAILABILITY_PATTERNS = gql`
    mutation UpdateStaffAvailabilityPatterns($workingPattern: updateWorkingPattern!) {
        updateWorkingPattern(workingPattern: $workingPattern) {
            workingPattern {
              dayNumber
              available
              startTime
              endTime
            }
        }
    }
`;

export const GET_STAFF_AVAILABILITY_MODIFICATIONS = gql`
    query GetStaffAvailabilityModifications($dateRange: myAvailabilityExceptionsDateRange) {
        myAvailabilityExceptions(dateRange: $dateRange) {
            exceptions {
                available
                approval
                date
                start
                end
            }
        }
    }
`;

export const CREATE_STAFF_AVAILABILITY_MODIFICATION = gql`
    mutation CreateStaffAvailabilityModification($createAvailabilityException: createAvailabilityException!) {
        createAvailabilityException(exception: $createAvailabilityException) {
            available
            date
            start
            end
        }
    }
`;

export const UPDATE_STAFF_AVAILABILITY_MODIFICATION = gql`
    mutation UpdateStaffAvailabilityModification($updateAvailabilityException: createAvailabilityException!) {
        updateAvailabilityException(exception: $updateAvailabilityException) {
            available
            date
            start
            end
        }
    }
`;

export const UPDATE_STAFF_AVAILABILITY_MODIFICATIONS = gql`
    mutation UpdateStaffAvailabilityModifications($exceptions: [updateAvailabilityExceptions]!) {
        updateAvailabilityExceptions(exceptions: $exceptions) {
            id
        }
    }
    `;

export const DELETE_STAFF_AVAILABILITY_MODIFICATION = gql`
    mutation DeleteStaffAvailabilityModification($deleteAvailabilityException: deleteAvailabilityException!) {
        deleteAvailabilityException(exceptionDetails: $deleteAvailabilityException) {
            available
            date
            start
            end
        }
    }
`;

// STAFF OVERTIME
export const GET_EMPLOYEE_OVERTIME_QUERY = gql`
    query GetStaffOvertime ($overtimeHistoryDetails : getEmployeeOvertimeHistory)
    {
        overtimeHistory (employeeOvertimeHistoryDetails: $overtimeHistoryDetails)
        {
            overTimeHours
            fullDate
        }
    }
`;

// ACCOUNT
export const RESET_PASSWORD = gql`
    mutation ResetPassword($employeeId: Guid!) {
        resetPassword(employeeId: $employeeId)
    }
`;
