import {useContext, useState} from "react";
import {useQuery} from "@apollo/client";
import {GET_MANAGEMENT_HIERARCHY} from "../pages/restaurants/RestaurantGql";
import {debugError} from "./Debug";
import {FilteredLocation} from "./LocationFilter";
import {getStaffName} from "../pages/staff/StaffUtils";
import {AuthContext} from "../providers/AuthProvider";

export const managerNotAssignedId = "00000000-0000-0000-0000-000000000000";
export const selectAllId = "FFFFFFFF-FFFF-FFFF-FFFF-FFFFFFFFFFFF";

export const getAreaManagers = (regionManagers: any[], regionManagerId?: string) => {
    if (regionManagerId) {
        const region = regionManagers.find((regionManager) => regionManager.identityId === regionManagerId);
        if (region) {
            return {regionId: region.identityId, areaManagers: region.areaManagers};
        }
    }
    return {regionId: regionManagers[0].identityId, areaManagers: regionManagers[0].areaManagers};
}

export const getSupervisors = (areaManagers: any[], areaManagerId?: string) => {
    if (areaManagerId) {
        const area = areaManagers.find((areaManger) => areaManger.identityId === areaManagerId);
        if (area) {
            return {areaId: area.identityId, supervisors: area.supervisors};
        }
    }
    return {areaId: areaManagers[0].identityId, supervisors: areaManagers[0].supervisors};
}

export const getLocations = (supervisors: any[], supervisorId?: string) => {
    if (supervisorId) {
        const supervisor = supervisors.find((supervisor) => supervisor.identityId === supervisorId);
        if (supervisor) {
            return {
                supervisorId: supervisor.identityId,
                locations: [...supervisor.stores].sort((a,b) => a.locationNumber - b.locationNumber)
            };
        }
    }
    return {
        supervisorId: supervisors[0].identityId,
        locations: [...supervisors[0].stores].sort((a,b) => a.locationNumber - b.locationNumber)
    };
}

export const getLocation = (locations: any[], locationId?: string) => {
    if (locationId) {
        const location = locations.find((location) => location.id === locationId);
        if (location) {
            return location;
        }
    }
    return locations[0];
}

export const getManagerIds = (managementHierarchy: any[], fl?: FilteredLocation) => {
    const {regionId, areaManagers} = getAreaManagers(managementHierarchy, fl?.regionId);
    const {areaId, supervisors} = getSupervisors(areaManagers, fl?.areaId);
    const {supervisorId, locations} = getLocations(supervisors, fl?.supervisorId);
    const location = getLocation(locations, fl?.locationId);
    return {regionId, areaId, supervisorId, location, areaManagers, supervisors, locations};
}

export const validateFilteredLocation = (managementHierarchy: any[], fl?: FilteredLocation) => {
    const {regionId, areaManagers} = getAreaManagers(managementHierarchy, fl?.regionId);
    const {areaId, supervisors} = getSupervisors(areaManagers, fl?.areaId);
    const {supervisorId, locations} = getLocations(supervisors, fl?.supervisorId);
    const location = getLocation(locations, fl?.locationId);
    let valid = fl !== undefined;
    const validated: FilteredLocation = {...fl};
    if (fl) {
        if (regionId !== fl.regionId && fl.regionId !== selectAllId) {
            validated.regionId = regionId;
            validated.areaId = areaId;
            validated.supervisorId = supervisorId;
            validated.locationId = location.id;
            validated.locationNumber = location.locationNumber;
            validated.name = location.name;
            valid = false;
        }
        if (areaId !== fl.areaId && fl.areaId !== selectAllId) {
            validated.areaId = areaId;
            validated.supervisorId = supervisorId;
            validated.locationId = location.id;
            validated.locationNumber = location.locationNumber;
            validated.name = location.name;
            valid = false;
        }
        if (supervisorId !== fl.supervisorId && fl.supervisorId !== selectAllId) {
            validated.supervisorId = supervisorId;
            validated.locationId = location.id;
            validated.locationNumber = location.locationNumber;
            validated.name = location.name;
            valid = false;
        }
        if (location.id !== fl.locationId && fl.locationId !== selectAllId) {
            validated.locationId = location.id;
            validated.locationNumber = location.locationNumber;
            validated.name = location.name;
            valid = false;
        }
    } else {
        validated.regionId = regionId;
        validated.areaId = areaId;
        validated.supervisorId = supervisorId;
        validated.locationId = location.id;
        validated.locationNumber = location.locationNumber;
        validated.name = location.name;
    }
    if (valid) {
        return {valid, validated: undefined};
    } else {
        return {valid, validated};
    }
}

const getAreaManagerLocations = (supervisors: any[]) => {
    const locations: any[] = [];
    supervisors.forEach((sv) => {
        locations.push(...getSupervisorLocations(sv.stores));
    });
    return locations;
}

const getSupervisorLocations = (stores: any[]) => {
    const locations: any[] = [];
    stores.forEach((s) => {
        locations.push({
            id: Number(s.id),
            locationNumber: Number(s.locationNumber),
            name: s.name
        })
    });
    return locations;
}

export const useManagementHierarchy = () => {
    const auth = useContext(AuthContext);
    const [managementHierarchy, setManagementHierarchy] = useState<any[] | undefined>();

    const {refetch: refetchManagementHierarchy} = useQuery(GET_MANAGEMENT_HIERARCHY, {
        skip: !auth.isAuthenticated() || process.env.REACT_APP_BUILD === 'staff' || auth.getUserRole() === 'Staff',
        onCompleted: (gqldata) => {
            setManagementHierarchy(gqldata.managementHierarchy);
        },
        onError: (error) => {
            debugError(`Get management hierarchy: ${error.message}`);
        }
    });

    const getSelectedLocations = (fl?: FilteredLocation) : any[] => {
        const locations: any[] = [];
        if (managementHierarchy) {
            const {areaManagers} = getAreaManagers(managementHierarchy, fl?.regionId);
            const {supervisors} = getSupervisors(areaManagers, fl?.areaId);
            const {locations: stores} = getLocations(supervisors, fl?.supervisorId);
            if (fl?.regionId === selectAllId) {
                managementHierarchy.forEach((rm) => {
                    rm.areaManagers.forEach((am) => {
                        locations.push(...getAreaManagerLocations(am.supervisors));
                    });
                });
            } else if (fl?.areaId === selectAllId) {
                areaManagers.forEach((am) => {
                    locations.push(...getAreaManagerLocations(am.supervisors));
                });
            } else if (fl?.supervisorId === selectAllId) {
                locations.push(...getAreaManagerLocations(supervisors));
            } else if (fl?.locationId === selectAllId) {
                locations.push(...getSupervisorLocations(stores));
            } else {
                const store = getLocation(stores, fl?.locationId);
                locations.push({
                    id: Number(store.id),
                    locationNumber: Number(store.locationNumber),
                    name: store.name
                });
            }
        }
        return locations.sort((a,b) => a.locationNumber - b.locationNumber);
    }

    const getSelectedLocation = (fl?: FilteredLocation) : any | undefined => {
        if (!managementHierarchy) return undefined;
        const {areaManagers} = getAreaManagers(managementHierarchy, fl?.regionId);
        const {supervisors} = getSupervisors(areaManagers, fl?.areaId);
        const {locations} = getLocations(supervisors, fl?.supervisorId);
        return getLocation(locations, fl?.locationId);
    }

    const getSelectedManagerId = (fl?: FilteredLocation) : string | undefined => {
        if (!managementHierarchy) return undefined;
        const {regionId, areaManagers} = getAreaManagers(managementHierarchy, fl?.regionId);
        const {areaId, supervisors} = getSupervisors(areaManagers, fl?.areaId);
        const {supervisorId, locations} = getLocations(supervisors, fl?.supervisorId);
        const location = getLocation(locations, fl?.locationId);
        if (fl?.regionId === selectAllId) {
            return undefined; // no manager is selected.
        } else if (fl?.areaId === selectAllId) {
            return regionId;
        } else if (fl?.supervisorId === selectAllId) {
            return areaId;
        } else if (fl?.locationId === selectAllId) {
            return supervisorId;
        }
        return location.identityId;
    };

    const getSeniorManagerName = (locationId: string, language: string) => {
        if (!managementHierarchy) return undefined;
        let regionManager = "";
        let areaManager = "";
        let supervisor = "";
        managementHierarchy.some((rm) => {
            return rm.areaManagers.some((am) => {
                return am.supervisors.some((sv) => {
                    const restaurant = sv.stores.find((s) => s.id === locationId);
                    if (restaurant) {
                        regionManager = rm.identityId === managerNotAssignedId ? "" : getStaffName(rm, language);
                        areaManager = am.identityId === managerNotAssignedId ? "" : getStaffName(am, language);
                        supervisor = sv.identityId === managerNotAssignedId ? "" : getStaffName(sv, language);
                    }
                    return restaurant !== undefined;
                });
            });
        });
        return {regionManager, areaManager, supervisor};
    }

    return {managementHierarchy, getSelectedLocations, getSelectedLocation, getSelectedManagerId, refetchManagementHierarchy, getSeniorManagerName};
};