import React, {FC, PropsWithChildren, useContext, useEffect, useState} from "react";
import {useLazyQuery} from "@apollo/client";
import {GET_STAFF_QUERY} from "../pages/staff/StaffGql";
import {GET_LOCATION_OPERATING_HOURS_QUERY} from "../pages/settings/SettingsGql";
import {debugError} from "../utils/Debug";
import {GeneralContext} from "./GeneralProvider";
import {ShiftBase} from "../pages/shifts/Shifts";
import {convertDateToGqlDate} from "../utils/DateHelper";
import {AuthContext} from "./AuthProvider";

export const RestaurantContext = React.createContext({
    employees: [] as any[] | undefined,
    operatingHours: [] as any[] | undefined,
    addAfterHours: (shift: ShiftBase) => {return {start: 0, end: 0};}
});

// Context logic
export const RestaurantProvider: FC<PropsWithChildren> = ({children}) => {
    const generalContext = useContext(GeneralContext);
    const auth = useContext(AuthContext);
    const [getEmployees, {data: employees}] = useLazyQuery(GET_STAFF_QUERY, {
        fetchPolicy: "no-cache" // cache needed?
    });

    const [operatingHours, setOperatingHours] = useState<any[] | undefined>();
    const [getLocationHours] = useLazyQuery(
        GET_LOCATION_OPERATING_HOURS_QUERY,
        {
            onCompleted: (data) => {
                setOperatingHours(data?.locationOperatingHours?.operatingHours);
            },
            onError: (error) => {
                debugError(`Get location operating hours: ${error.message}`);
            }
        }
    );

    useEffect(() => {
        const employee = generalContext.userData?.myEmployee;
        if (employee?.role === "Staff") {
            const locationId = employee.restaurants[0].id;
            getLocationHours({
                variables: {
                    locationId: locationId
                }
            });
        }
    }, [getLocationHours, generalContext.userData]);

    useEffect(() => {
        const locationId = generalContext.filteredLocation?.locationId
        if (locationId) {
            if (process.env.REACT_APP_BUILD !== 'staff' && auth.getUserRole() !== 'Staff') {
                getEmployees({
                    variables: {
                        dateAndLocation: {
                            locationId: locationId,
                            date: convertDateToGqlDate(new Date())
                        }
                    }
                });
            }
            getLocationHours({
                variables: {
                    locationId: locationId
                }
            });
        }
    }, [getEmployees, getLocationHours, generalContext.filteredLocation?.locationId]);

    const addAfterHours = (shift: ShiftBase) => {
        if (operatingHours) {
            const dayNumber = generalContext.getDayNumber(new Date(shift.date));
            const hours = operatingHours[dayNumber];
            let end = shift.end;
            if (end >= hours.closeTime) {
                end = hours.closeTime + shift.afterHours;
                return {start: shift.start, end};
            }
        }
        return {start: shift.start, end: shift.end};
    }

    const contextObj = {
        employees: employees?.staffByLocation,
        operatingHours: operatingHours,
        addAfterHours: addAfterHours
    };

    return <RestaurantContext.Provider value={contextObj}>{children}</RestaurantContext.Provider>;
};