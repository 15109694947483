//USERS PAGE
import {gql, useLazyQuery} from "@apollo/client";
import {GeneralContext} from "../../providers/GeneralProvider";
import {useCallback, useContext, useEffect, useState} from "react";
import {selectAllId} from "../../utils/ManagementHierarchy";

export enum contractTypeIdMapping {
    fullTimeId = "f4316689-c3ee-4460-bc25-d31d66b16903",
    regularId = "76c3b675-b63c-433b-a7be-118a0124ac5b",
    partTimeId = "833f14a9-369b-4f8f-8115-27be8bbb2b2c",
    studentId = "aa33e3c6-90e1-4659-95d6-f22c30a365ca",
    casualId = "57366ee7-2923-49db-b82b-c618bf6e7018",
    foreignWorkerId = "554864fd-81cd-46c4-9f9f-7515b5e70460",
    foreignStudentId = "ed890639-1f68-4759-a4cf-2283af959be2",
    foreignStudentHolidayId = "3503e84d-5c65-4c18-bd2c-50c352be3107",
    highSchoolStudentId = "db776b17-da22-4f01-97fc-54512179d345"
}

export const GET_ROLES = gql`
    query Roles {
        roles {
            id
            name
        }
    }
`;

export const GET_USER_BY_ID_QUERY = gql`
    query GetUserById($id: Guid!) {
        employee(id: $id) {
            contract {
                    contractTypeId
                    contractModificationId
                    contractModificationName
                    hourlyRate
                    holidayHourlyRate
                    lateWorker
                    foreigner
                }
            identityId
            userName
            email
            firstName
            lastName
            kanjiFirstName
            kanjiLastName
            role
            employeeId
            restaurants {
                id
                name
                locationNumber
            }
            stationId
            leaderRole
            title
            baseSalary
            globalPremium
            managerialPremium
            ignoreHourContribution
            isRosterable
            isDeactivated
        }
    }
`;

export const CREATE_USER_MUTATION = gql`
    mutation CreateUser($employee: createEmployee!) {
        createUserAndEmployee(employee: $employee) {
            identityId
        }
    }
`;

export const UPDATE_USER_MUTATION = gql`
    mutation UpdateUser($employee: updateEmployee!) {
        updateEmployee(employee: $employee) {
            contract {
                contractTypeId
                contractModificationId
                contractModificationName
                lateWorker
            }
            identityId
            email
            firstName
            lastName
            kanjiFirstName
            kanjiLastName
            role
            employeeId
            ignoreHourContribution
            isDeactivated
            restaurants {
                id
                name
            }
        }
    }
`;

export const DELETE_USER_MUTATION = gql`
    mutation DeleteUser($id: Guid!) {
        deleteEmployee(id: $id) {
            identityId
        }
    }
`;

// USER DATA
export const GET_USER_DATA_QUERY = gql`
    query GetUserData {
        myEmployee {
            firstName
            identityId
            lastName
            kanjiFirstName
            kanjiLastName
            role
            restaurants {
                id
                name
                locationNumber
            }
            contract {
                contractTypeId
                contractModificationName
                lateWorker
                foreigner
            }
            isRosterable
        }
    }
`;

export const GET_USERS_BY_LOCATION = gql`
    query GetUsersByLocation ($locationId: Int) {
        employeesByLocation (locationId: $locationId) {
            employees {
                identityId
                firstName
                lastName
                kanjiFirstName
                kanjiLastName
                role
                employeeId
                isDeactivated
                restaurants {
                    name
                    locationNumber
                }
            }
        }
    }
`;

export const GET_USERS_BY_MANAGER = gql`
    query GetUsersByManager ($managerId: Guid!) {
        employeesForManager (managerId: $managerId) {
            employees {
                identityId
                firstName
                lastName
                kanjiFirstName
                kanjiLastName
                role
                employeeId
                restaurants {
                    name
                    locationNumber
                }
            }
        }
    }
`;

export const useUserList = () => {
    const generalContext = useContext(GeneralContext);
    const {getSelectedManagerId, filteredLocation} = generalContext;
    const [usersList, setUsersList] = useState<any[] | undefined>();
    const [showLocation, setShowLocation] = useState<boolean>(false);

    const [
        getUsersByLocation,
        {
            loading: byLocationLoading,
            error: byLocationError,
        }
    ] = useLazyQuery(
        GET_USERS_BY_LOCATION,
        {
            fetchPolicy: "no-cache",
            onCompleted: (data) => {
                setUsersList(data.employeesByLocation.employees);
            }
        }
    );

    const [
        getUsersByManager,
        {
            loading: byManagerLoading,
            error: byManagerError,
        }
    ] = useLazyQuery(
        GET_USERS_BY_MANAGER,
        {
            fetchPolicy: "no-cache",
            onCompleted: (data) => {
                setUsersList(data.employeesForManager.employees);
            }
        }
    );

    const loading = byManagerLoading || byLocationLoading;
    const error = byManagerError || byLocationError;

    const getUsersList = useCallback(() => {
        if (filteredLocation) {
            if (filteredLocation.locationId &&
                filteredLocation.locationId !== selectAllId &&
                filteredLocation.supervisorId !== selectAllId &&
                filteredLocation.areaId !== selectAllId &&
                filteredLocation.regionId !== selectAllId) {
                getUsersByLocation({
                    variables: {
                        locationId: filteredLocation.locationId
                    }
                });
                setShowLocation(false);
            } else {
                const managerId = getSelectedManagerId();
                if (managerId) {
                    getUsersByManager({
                        variables: {
                            managerId: managerId
                        }
                    });
                    setShowLocation(true);
                }
            }
        }
    }, [getSelectedManagerId, getUsersByManager, getUsersByLocation, filteredLocation, setShowLocation]);

    useEffect(() => {
        getUsersList();
    }, [getUsersList]);

    return {usersList, loading, error, getUsersList, showLocation};
}